<template>
    <div>
        <!-- CAROUSEL-->
    <b-row>
      <b-col cols="12" >
        <div>
          <b-carousel indicators :interval="3000" controls >
            <b-carousel-slide      
                v-if="resources!=null"          
                :img-src="resources.img_header_principal"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row>

        <br>
      
      <!--   <h1 class="titles">
          
          <span style="color: #d82125">N</span>
          <span style="color: #d02175">o</span>
          <span style="color: #f6c921">r</span>
          <span style="color: #d02175">m</span>
          <span style="color: #4d944a">a</span>
          <span style="color: #07aaba">t</span>
          <span style="color: #d82125">i</span>
          <span style="color: #d02175">v</span>
          <span style="color: #f6c921">i</span>
          <span style="color: #d02175">d</span>
          <span style="color: #07aaba">a</span>
          <span style="color: #4d944a">d</span>
          

        </h1> -->

        
        <h1 class="titles mt-4 mb-4">
          <span class="c-shadow-sm" style="color: #8b8c8c; padding:15px;">Mejora Regulatoria 2023</span>
        </h1>

        <b-row align-h="center">
          <b-col>
              <b-button @click="$router.push('/mejoraregulatoria2022')" variant="secondary" pill size="sm">Ir a Mejora Regulatoria 2022</b-button>
          </b-col>
        </b-row>
<!--         <b-row align-h="center" id="flipbook-body" 
            :class="{ 'has-mouse': hasMouse }"
            @touchstart="hasMouse = false" style="height: 100vh;">
            
            <Flipbook class="flipbook" :pages="pages" v-slot="flipbook" >
                
        
                <div class="action-bar my-3">
                    
                    <b-icon
                        variant="dark"
                        font-scale="1.5"
                        class="pointer mx-2"
                        icon="arrow-left-circle-fill"
                        :class="{ disabled: !flipbook.canFlipLeft }"
                        @click="flipbook.flipLeft"
                    >
                    </b-icon>
                
                    <span class="mx-2" style="color:black;">
                    Pagina {{ flipbook.page }} de {{ flipbook.numPages }}
                    </span>
                
                    <b-icon
                        variant="dark"
                        font-scale="1.5"
                        class="pointer mx-2"
                        icon="arrow-right-circle-fill"
                        :class="{ disabled: !flipbook.canFlipRight }"
                        @click="flipbook.flipRight"
                    >
                    </b-icon>
                                    
                </div>

            </Flipbook>            
        </b-row>

        <b-button pill variant="success" class="mt-3">
          <a style="color:white; text-decoration: none;" target="_blank" href="https://drive.google.com/file/d/1fviu15J-0Q1Uu3XV6JLZFoSH6wW4OE2A/view?usp=sharing"> 
            Descargar PDF 
            <b-icon icon="download"></b-icon> 
          </a>  
        </b-button>
 -->
      
    <!-- <h1 class="titles">
      <span style="color: #8b8c8c">Bando Municipal</span>
    </h1> -->

    <b-row align-h="center">
      <b-col cols="11" md="10" lg="8" xl="6" class="mt-3 mb-3">
        <h5><b> ¿Qué es Mejora Regulatoria?</b></h5>
        <p></p>
        <p>La Mejora Regulatoria es una política pública que consiste en la generación de normas claras, de trámites y servicios simplificados, así como de instituciones eficaces para su creación y aplicación, que se orientan a obtener el mayor valor posible de los recursos disponibles y del óptimo funcionamiento de las actividades comerciales, industriales, productivas, de servicios y de desarrollo humano de la sociedad en su conjunto.</p>
        <p>El propósito de la mejora regulatoria radica entonces en procurar los mayores beneficios para la sociedad con los menores costos posibles, mediante la formulación normativa de reglas e incentivos que estimulen la innovación, la confianza en la economía, la productividad, la eficiencia y la competitividad a favor del crecimiento, bienestar general y desarrollo humano.</p>
        <p>La Ley para la Mejora Regulatoria del Estado de México y Municipios, establece las bases para un proceso de Mejora Regulatoria integral, continua y permanente a nivel municipal, que bajo los principios de máxima utilidad para la sociedad y la transparencia para su elaboración, logre promover la eficacia de su gobierno, abata la corrupción promueva la transparencia y fomente el desarrollo socioeconómico y la competitividad de cada Municipio.</p>
      </b-col>
    </b-row>
        
   <b-row align-h="center">
    <b-col  cols="11" md="10" lg="8" xl="6" class="mt-5 mb-5">
      <b-tabs content-class="mt-3" fill>
        <b-tab active>
          <template #title>
            <h6><b-icon icon="bookmarks-fill"/> Comisión Municipal <b></b></h6>
          </template>
          <b-row align-h="center">
            <b-col cols="12" class="mt-4 mb-3">
              <h3 class="titles">
                <span style="color: #8b8c8c"> <b-icon icon="bookmarks-fill"/> Comisión Municipal</span>
              </h3>
            </b-col>
            <b-col  class="mt-3 mb-4">
              <b-row>

                <b-col cols="12" class="mt-3 mb-4">
                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle="'accordion-docmr'" style="width:100%; text-align:left;"><b-icon icon="files"/> Documentos</b-button>
                    </b-card-header>
                    <b-collapse :id="'accordion-docmr'" visible accordion="my-accordion-mr" role="tabpanel">
                      <b-row align="left">
                        <b-col  style="margin:20px;" v-for="(doc, index) in this.docsComisionM" :key="index">
                            <a :href="doc.link" target="blank">
                              <b-icon icon="file-earmark-medical" font-scale="3"/>
                            </a>
                            <h6> <b> {{doc.nombre}} </b></h6>
                        </b-col>
                      </b-row>
                    </b-collapse>
                  </b-card>
                </b-col>         

    

                <b-col cols="12" class="mt-5 mb-3">
                  <h3 class="titles">
                    <span style="color: #8b8c8c"> <b-icon icon="bookmarks-fill"/> Cómites Internos</span>
                  </h3>
                </b-col>

                
                <b-col cols="12" v-for="(area, i) in this.comisiones" :key="i">
                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle="'accordion-'+i" style="width:100%; text-align:left;"><b-icon icon="files"/> {{area.area}}</b-button>
                    </b-card-header>
                    <b-collapse :id="'accordion-'+i" accordion="my-accordion" role="tabpanel">
                      <b-row align="left" v-for="(archivo,i) in area.archivos" :key="i">
                        <b-col  style="margin:20px;">
                            <a :href="archivo.link" target="blank">
                              <b-icon icon="file-text" font-scale="3"/>
                            </a>
                            <h6>{{archivo.nombre}}</h6>
                        </b-col>
                      </b-row>
                    </b-collapse>
                  </b-card>
                </b-col>                
                
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <h6><b-icon icon="book-fill"/> <b>Catálogo Municipal de Regulaciones</b></h6>
          </template>
          <b-row align-h="center">
            <b-col cols="12" class="mt-4 mb-3">
              <h3 class="titles">
                <span style="color: #8b8c8c"><b-icon icon="book-fill"/> Catálogo Municipal de Regulaciones</span>
              </h3>
            </b-col>
            <b-col class="mt-3 mb-5">
              <b-row align="left">
                <b-col cols="12" v-for="(r, index) in regulaciones" :key="index" class="mt-2">
                  <h5 >
                    <b-icon icon="file-earmark-text-fill"/>  <a style="color:black;" :href="r.link" target="_blank">{{r.nombre}}</a> 
                  </h5>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <h6><b-icon icon="book-fill"/> <b>Agenda Regulatoria</b></h6>
          </template>
          <b-row align-h="center">
            <b-col cols="12" class="mt-4 mb-3">
              <h3 class="titles">
                <span style="color: #8b8c8c"><b-icon icon="book-fill"/> Agenda Regulatoria</span>
              </h3>
            </b-col>
            <b-col class="mt-3 mb-5">
              <b-row align="left">
                <b-col cols="12" v-for="(r, index) in agenda" :key="index" class="mt-2">
                  <h5 >
                    <b-icon icon="file-earmark-text-fill"/>  <a style="color:black;" :href="r.link" target="_blank">{{r.nombre}}</a> 
                  </h5>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <h6><b-icon icon="list-check"/> <b>REMTyS</b></h6>
          </template>
          <b-row>
            <b-col cols="12" class="mt-4 mb-3">
              <h3 class="titles">
                <span style="color: #8b8c8c"><b-icon icon="list-check"/> Registro Municipal de Trámites y Servicios</span>
              </h3>
            </b-col>
            <b-col class=" mt-3 mb-4" >
              <b-row>
                <b-col cols="12" v-for="(area, i) in this.remtys" :key="i">
                  <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                          <b-button block v-b-toggle="'accordion-'+i" style="width:100%; text-align:left;"><b-icon icon="files"/> {{area.nombre}}</b-button>
                      </b-card-header>
                      <b-collapse :id="'accordion-'+i" accordion="my-accordion" role="tabpanel">
                        <b-row align="left" v-for="(archivo,i) in area.archivos" :key="i">
                          <b-col  style="margin:20px;">
                            
                            <a :href="archivo.link" target="blank">
                              <b-icon icon="file-text" font-scale="3"/>
                            </a>
                            <h6>{{archivo.nombre}}</h6>
                        
                          </b-col>
                        </b-row>
                      </b-collapse>
                      </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <h6><b-icon icon="search"/> <b>Consulta Pública</b></h6>
          </template>
          <b-row align="cemter">
            <b-col cols="12" class="mt-4 mb-3">
              <h3 class="titles">
                <span style="color: #8b8c8c"><b-icon icon="search"/> Consulta Pública</span>
              </h3>
            </b-col>
          </b-row>
          <b-row align="left">
            <b-col  class=" mt-3 mb-5">
              <p>Te invitamos a conocer y participar en la consulta pública de los proyectos que la administración pública municipal a través de las diferentes áreas esta realizando en beneficio de la ciudadanía, la intención es que las bondades de las regulaciones sean superiores a los costos para el ciudadano, así como la simplificación de trámites y servicios.</p>
              <p>En este apartado podrás conocer el proyecto de Reglamento de Mejora Regulatoria 2022. nos interesa conocer tu opinión, comentarios y propuestas.</p>  
              <br>
              <h6><b>Vigencia de la consulta:</b> </h6>
              <h6><b>Proyecto:</b> </h6>
              <h6><b>Quienes pueden participar:</b> </h6>
              <h6><b>Contacto:</b> </h6>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <h6><b-icon icon="exclamation-triangle-fill"/> <b>Protesta Ciudadana</b></h6>
          </template>
          <b-row align-h="center">
            <b-col cols="12" class="mt-4 mb-3">
              <h3 class="titles">
                <span style="color: #8b8c8c"><b-icon icon="exclamation-triangle-fill"/> Protesta Ciudadana</span>
              </h3>
            </b-col>
            <b-col class="mt-3 mb-4">
              <h4><b>¿Qué es el sistema de protesta ciudadana?</b></h4>
              <p>Es el procedimiento mediante el cual se da seguimiento a peticiones y/o inconformidades ciudadanas por falta de respuesta en solicitudes de trámites y/o servicios previstos en la normatividad aplicable.</p>
              <br>
              <h4><b>¿Cuál es su objetivo?</b></h4>
              <p>Dar a conocer mediante escrito, la inconformidad al respecto de la presunta negativa, dilación o falta de respuesta o solicitud de requisitos no previstos en la normatividad aplicable o en el registro correspondiente a su solicitud de trámite y/o servicio.</p>
              <br>
              <h4><b>¿En qué casos me puedo inconformar?</b></h4>
              <p>El solicitante podrá presentar una Protesta Ciudadana cuando con acciones u omisiones el servidor público encargado del Trámite o Servicio niegue la gestión sin causa justificada.</p>
              <br>
              <h4><b>¿Cuál es el plazo de respuesta?</b></h4>
              <p>La Protesta Ciudadana será revisada por la Comisión o las Comisiones Municipales, según corresponda, quienes emitirán su opinion en un plazo de cinco días, dando contestación al ciudadano que la presentó, dará vista de la misma al Sujeto Obligado y en su caso, al órgano competente en materia de responsabilidades.</p>
            </b-col>
            <b-col cols="12" class="mt-2 mb5">
              <a href="https://teotihuacan.gob.mx/files/mregulatoria/formato_protesta.docx" target="blank">
                <b-icon icon="file-earmark-medical" font-scale="3"/>
              </a>
              <h6> <b> Formato de Protesta Ciudadana </b></h6>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab >
          <template #title>
            <h6><b-icon icon="people-fill"/> <b>Registro Municipal de Inspectores</b></h6>
          </template>
          <b-row align-h="center">
            <b-col cols="12" class="mt-4 mb-3">
              <h3 class="titles">
                <span style="color: #8b8c8c"><b-icon icon="people-fill"/> Registro Municipal de Inspectores</span>
              </h3>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-col>
   </b-row>

          
      
        <!-- <b-row class="mt-5" align-h="center">
            <b-col cols="11" md="10" lg="8">
            <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 style="width:100%;   text-align:left; "><b-icon icon="files"/> Regulaciones Municipales</b-button>
                </b-card-header>
                <b-collapse id="accordion-1"  accordion="my-accordion" role="tabpanel">
                    <b-card-body style="text-align: left">
                        <b-card-text><a href="https://www.tepetlaoxtoc.gob.mx"><b-icon icon="file-earmark-text-fill"></b-icon> Plan de Desarrollo Municipal</a></b-card-text>
                        <b-card-text><a href="https://www.tepetlaoxtoc.gob.mx"><b-icon icon="file-earmark-text-fill"></b-icon> Reglamentos Internos</a></b-card-text>
                        <b-card-text><a href="https://www.tepetlaoxtoc.gob.mx"><b-icon icon="file-earmark-text-fill"></b-icon> Manuales de Operación</a></b-card-text>
                    </b-card-body>
                </b-collapse>
                </b-card>
            

                <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 style="width:100%; text-align:left;"><b-icon icon="files"/> Mejora Regulatoria</b-button>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                    <b-card-body style="text-align: left">
                        <b-card-text><a href="https://www.tepetlaoxtoc.gob.mx"><b-icon icon="file-earmark-text-fill"></b-icon> Plan Anual de Mejora Regulatoria 2022</a></b-card-text>
                        <b-card-text><a href="https://www.tepetlaoxtoc.gob.mx"><b-icon icon="file-earmark-text-fill"></b-icon> Avances Primer Trimestre</a></b-card-text>
                        <b-card-text><a href="https://www.tepetlaoxtoc.gob.mx"><b-icon icon="file-earmark-text-fill"></b-icon> Acta Constitutiva CMMR</a></b-card-text>
                    </b-card-body>
                </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 style="width:100%; text-align:left;"><b-icon icon="files"/> Periodico Oficial Municipal</b-button>
                </b-card-header>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                    <b-card-body style="text-align: left">
                        <b-card-text><a href="https://www.tepetlaoxtoc.gob.mx"><b-icon icon="file-earmark-text-fill"></b-icon> Gaceta 2022</a></b-card-text>
                        <b-card-text><a href="https://www.tepetlaoxtoc.gob.mx"><b-icon icon="file-earmark-text-fill"></b-icon> Gaceta 2021</a></b-card-text>
                        <b-card-text><a href="https://www.tepetlaoxtoc.gob.mx"><b-icon icon="file-earmark-text-fill"></b-icon> Gaceta 2020</a></b-card-text>
                        <b-card-text><a href="https://www.tepetlaoxtoc.gob.mx"><b-icon icon="file-earmark-text-fill"></b-icon> Gaceta 2019</a></b-card-text>
                    </b-card-body>
                </b-collapse>
                </b-card>
            </div>
            </b-col>
        </b-row> -->
    </div>
</template>


<script>
import Flipbook from "flipbook-vue";
import axios from 'axios';

export default {
  components: {
    Flipbook,
  },
  data() {
    return {
      resources: null,
      docsComisionM:[
        {nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/ACTA_CONSTITUTIVA_CMMR.pdf"},
        //{nombre:"Programa Anual 2023", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/Plan_Anual_MR_2023.pdf"},
        {nombre:"Programa Anual 2023", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/Plan_Anual_MR_2023_ACTA.pdf"},
        {nombre:"Primera Sesión", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/ACTA_PRIMERA_SESION_COMISION_MUNICIPAL_2023.pdf"},
        {nombre:"Segunda Sesión", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/ACTA_SEGUNDA_SESION_COMISION_MUNICIPAL_2023.pdf"},
        {nombre:"Tercera Sesión", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/ACTA_TERCERA_SESION_COMISION_MUNICIPAL_2023.pdf"},
        {nombre:"Cuarta Sesión", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/ACTA_CUARTA_SESION_COMISION_MUNICIPAL_2023.pdf"},

      ],
      regulaciones:[
        {nombre:"Constitución Política de los Estados Unidos Mexicanos.", link:"https://teotihuacan.gob.mx/files/mregulatoria/regulaciones/CPEUM.pdf"},
        {nombre:"Constitución Política del Estado Libre y Soberano de México.", link:"https://teotihuacan.gob.mx/files/mregulatoria/regulaciones/CPELSM.pdf"},
        {nombre:"Ley de Competitividad y Ordenamiento Comercial del Estado de México.", link:"https://teotihuacan.gob.mx/files/mregulatoria/regulaciones/ley_competitividad_ordenamiento_comercial_estado_mexico.pdf"},
        {nombre:"Ley de Fomento Económico para el Estado de México.", link:"https://teotihuacan.gob.mx/files/mregulatoria/regulaciones/ley_fomento_economico_estado_mexico.pdf"},
        {nombre:"Ley de Gobierno Digital del Estado de México y Municipios.", link:"https://teotihuacan.gob.mx/files/mregulatoria/regulaciones/ley_gobierno_digital_estado_mexico_municipios_ley_periodico_oficial_gaceta_gobierno.pdf"},
        {nombre:"Ley para la Mejora Regulatoria del Estado de México y sus Municipios.", link:"https://teotihuacan.gob.mx/files/mregulatoria/regulaciones/ley_mejora_regulatoria_estado_mexico_municipios.pdf"},
        {nombre:"Adición la Ley de Competitividad y Ordenamiento Comercial del Estado de México.", link:"https://teotihuacan.gob.mx/files/mregulatoria/regulaciones/adicion_ley_competitividad_ordenamiento_comercial_estado_mexico_municipios.pdf"},
        {nombre:"Reformas a la Ley para la Mejora Regulatoria del Estado de México y sus Municipios.", link:"https://teotihuacan.gob.mx/files/mregulatoria/regulaciones/reformas_ley_mejora_regulatoria_estado_mexico_municipios.pdf"},
        {nombre:"Reformas al Reglamento de la Ley para la Mejora Regulatoria del Estado de México y sus Municipios.", link:"https://teotihuacan.gob.mx/files/mregulatoria/regulaciones/reformas_ley_mejora_regulatoria_estado_mexico_municipios.pdf"},
        {nombre:"Reglamento de la Ley de Competitividad y Ordenamiento Comercial del Estado de México.", link:"https://teotihuacan.gob.mx/files/mregulatoria/regulaciones/reglamento_ley_competitividad_ordenamiento_comercial_estado_mexico.pdf"},
        {nombre:"Reglamento de la Ley para la Mejora Regulatoria del Estado de México y sus Municipios.", link:"https://teotihuacan.gob.mx/files/mregulatoria/regulaciones/reglamento_ley_mejora_regulatoria_estado_mexico_municipios.pdf"},
        {nombre:"Lineamientos para la Elaboración del Estudio de Impacto Regulatorio para los Municipios del Estado de México.", link:"https://teotihuacan.gob.mx/files/mregulatoria/regulaciones/lineamientos_elaboracion_impacto_regulatorio_municipio_estado_mexico.pdf"},
        {nombre:"Reglamento para la Mejora Regulatoria del Municipio de Teotihuacán.", link:"https://teotihuacan.gob.mx/files/mregulatoria/regulaciones/reglamento_mr_teotihuacan.pdf"},
        {nombre:"Reglamento interior de la comisión municipal de Mejora Regulatoria del Municipio de Teotihuacán.", link:"https://teotihuacan.gob.mx/files/mregulatoria/regulaciones/reglamentoi_mr_teotihuacan.pdf"},
        {nombre:"Manual de Operación del Catálogo Municipal de Regulaciones del Municipio de Teotihuacán.", link:"https://teotihuacan.gob.mx/files/mregulatoria/regulaciones/manual_operacion_mr.pdf"},
      ],
      agenda:[
        {nombre:"Manuales de Organización", link:"https://teotihuacan.gob.mx/files/mregulatoria/agenda/MANUALES%20DE%20ORGANIZACION"},
        {nombre:"Códigos de Ética", link:"https://teotihuacan.gob.mx/files/mregulatoria/agenda/CODIGOS%20ETICA"},
        
      ],
      remtys:[
        {nombre:"Comisaria de Seguridad Ciudadana", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/20%20COMISARIA%20DE%20SEGURIDAD.pdf"}]},
        {nombre:"Contraloria municipal", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/33%20CONTRALORIA%20MUNICIPAL.pdf"}]},
        {nombre:"Coordinacion de cultura Cultura Y Museo", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/30%20COORDINACION%20DE%20CULTURA%20Y%20MUSEO.pdf"}]},
        {nombre:"Coordinación de Educacion", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/17%20COORDINACION%20DE%20EDUCACION.pdf"}]},
        {nombre:"Coordinacion de la unidad juridica La Unidad Juridica Y Consultiva", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/21%20COORDINACION%20DE%20UNIDAD%20JURIDICA%20Y%20CONSULTIVA.pdf"}]},
        {nombre:"Coordinación operativa de proteccion civil De Proteccion Civil Y Bomberos", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/4%20COORDINACION%20DE%20PROTECCION%20CIVIL%20Y%20BOMBEROS.pdf"}]},
        {nombre:"Defensoria de Derechos Humanos", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/12%20DEFENSORIA%20MUNICIPAL%20DE%20DERECHOS%20HUMANOS.pdf"}]},
        {nombre:"Dirección de Bienestar Animal", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/29%20DIRECCION%20DE%20BIENESTAR%20ANIMAL.pdf"}]},
        {nombre:"Dirección de Catastro", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/13%20DIRECCION%20DE%20CATASTRO.pdf"}]},
        {nombre:"Dirección de Desarrollo Economico", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/2%20DIRECCION%20DE%20DESARROLLO%20ECONOMICO.pdf"}]},
        {nombre:"Dirección de Desarrollo Metropolitano", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/25%20DIRECCION%20DE%20DESARROLLO%20METROPOLITANO.pdf"}]},
        {nombre:"Dirección de Desarrollo Social", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/1%20DIRECCION%20DE%20DESARROLLO%20SOCIAL%20EDUCACION%20Y%20CULTURA.pdf"}]},
        {nombre:"Dirección de Desarrollo Urbano", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/32%20DIRECCION%20DE%20DESARROLLO%20URBANO.pdf"}]},
        {nombre:"Dirección de Fomento Agropecuario", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/14%20DIRECCION%20DE%20FOMENTO%20AGROPECUARIO.pdf"}]},
        {nombre:"Dirección de Fomento Artesanal", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/27%20DIRECCION%20DE%20FOMENTO%20ARTESANAL.pdf"}]},
        {nombre:"Dirección de Gobierno", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/26%20DIRECCION%20DE%20GOBIERNO.pdf"}]},
        {nombre:"Dirección de La Mujer", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/11%20DIRECCION%20DE%20LA%20MUJER.pdf"}]},
        {nombre:"Dirección de Medio Ambiente", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/31%20DIRECCION%20DE%20MEDIO%20AMBIENTE.pdf"}]},
        {nombre:"Dirección de Participacion Ciudadana", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/24%20DIRECCION%20DE%20PARTICIPACION%20CIUDADANA.pdf"}]},
        {nombre:"Dirección de Salud", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/18%20DIRECCION%20DE%20SALUD.pdf"}]},
        {nombre:"Dirección de Servicios Publicos", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/5%20DIRECCI%C3%93N%20DE%20SERVICIOS%20PUBLICOS.pdf"}]},
        {nombre:"Dirección de_transporte Transporte Y Vialidad", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/6%20DIRECCION%20DE%20TRANSPORTE%20Y%20VIALIDAD.pdf"}]},
        {nombre:"Dirección de Turismo", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/19%20DIRECCION%20DE%20TURISMO.pdf"}]},
        {nombre:"Imcufidet", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/7%20IMCUFIDET.pdf"}]},
        {nombre:"Instituto municiupal_de De La Juventud", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/15%20DIRECCION%20DE%20LA%20JUVENTUD.pdf"}]},
        {nombre:"Oficialia mediadora Conciliadora", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/23%20OFICIAL%20MEDIADOR-CONCILIADOR%20Y%20CALIFICADORA.pdf"}]},
        {nombre:"Organismo descentralizado agua Agua Potable Odeapast", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/28%20DIRECCION%20DEL%20ORGANISMO%20PUBLICO%20DEL%20AGUA%20POTABLE%2C%20ALCANTARILLADO%20Y%20SANEAMIENTO%20DE%20TEOTIHUACAN.pdf"}]},
        {nombre:"Registro civil De Teotihuacan", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/22%20REGISTRO%20CIVIL.pdf"}]},
        {nombre:"Regulacion comercial industrial y Industrial Y De Servicios", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/3%20COORDINACION%20DE%20REGULACION%20COMERCIAL%2C%20INDUSTRIAL%20Y%20DE%20SERVICIOS.pdf"}]},
        {nombre:"Secretaria del Ayuntamiento", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/9%20SECRETARIA%20%20DEL%20AYUNTAMIENTO.pdf"}]},
        {nombre:"Sistema municipal Dif", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/8%20DIF%20TEOTIHUACAN.pdf"}]},
        {nombre:"Tesoreria municipal De Teotihuacan", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/16%20TESORERIA%20MUNICIPAL.pdf"}]},
        {nombre:"Titular de la unidad La Unidad De Transparencia", archivos:[{nombre:"Trámites", link:"https://teotihuacan.gob.mx/files/mregulatoria/2023/remtys/10%20UNIDAD%20DE%20TRANSPARENCIA.pdf"}]},



      ],
      comisiones: [
        {area:"Comisaria de Seguridad Ciudadana",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/COMISARIA_DE_SEGURIDAD_CIUDADANA.pdf"}]},
        {area:"Contraloria municipal",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/CONTRALORIA_MUNICIPAL.pdf"}]},
        {area:"Coordinación de Adquisiciones",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/COORDINACION_DE_ADQUISICIONES.pdf"}]},
        {area:"Coordinación de Comunicacion Social",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/COORDINACION_DE_COMUNICACION_SOCIAL.pdf"}]},
        {area:"Coordinación de_cultura Cultura Y Museo",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/COORDINACION_DE_CULTURA_Y_MUSEO.pdf"}]},
        {area:"Coordinación de Educacion",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/COORDINACION_DE_EDUCACION.pdf"}]},
        {area:"Coordinación de Fomento Artesanal",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/COORDINACION_DE_FOMENTO_ARTESANAL.pdf"}]},
        {area:"Coordinación Juridica Y Consultiva",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/COORDINACION_DE_LA_JURIDICA_Y_CONSULTIVA.pdf"}]},
        {area:"Coordinación de Patrimonio Municipal",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/COORDINACION DE PATRIMONIO MUNICIPAL.pdf"}]},
        {area:"Coordinación de Regulacion Comercial",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/COORDINACION_DE_REGULACION_COMERCIAL.pdf"}]},
        {area:"Coordinación de Recursos Humanos",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/COORDINACION DE RECURSOS HUMANOS.pdf"}]},        
        {area:"Coordinación operativa de proteccion civil De Proteccion Civil Y Bomberos",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/COORDINACION_OPERATIVA_DE_PROTECCION_CIVIL_Y_BOMBEROS.pdf"}]},
        {area:"Coordinación de Las Tecnologias",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/COORDINACION_DE_LAS_TECNOLOGIAS.pdf"}]},
        {area:"Coordinación de Logística",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/COORDINACION DE LOGISTICA Y EVENTOS ESPECIALES.pdf"}]},       
        {area:"Defensoria municipal de De Derechos Humanos",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DEFENSORIA_MUNICIPAL_DE_DERECHOS_HUMANOS.pdf"}]},
        {area:"Dif",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIF.pdf"}]},
        {area:"Dirección de Administracion",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_ADMINISTRACION.pdf"}]},
        {area:"Dirección de Bienestar Animal",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_BIENESTAR_ANIMAL.pdf"}]},
        {area:"Dirección de Catastro",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_CATASTRO.pdf"}]},
        {area:"Dirección de Desarrollo Economico",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_DESARROLLO_ECONOMICO.pdf"}]},
        {area:"Dirección de Desarrollo Metropolitano",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_DESARROLLO_METROPOLITANO.pdf"}]},
        {area:"Dirección de Desarrollo Social",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_DESARROLLO_SOCIAL.pdf"}]},
        {area:"Dirección de Desarrollo Urbano",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION DE DESARROLLO URBANO.pdf"}]},
        {area:"Dirección de Enlaces Intermunicipales",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_ENLACES_INTERMUNICIPALES.pdf"}]},
        {area:"Dirección de Fomento Agropecuario",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_FOMENTO_AGROPECUARIO.pdf"}]},
        {area:"Dirección de Gobierno",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_GOBIERNO.pdf"}]},
        {area:"Dirección de La Mujer",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_LA_MUJER.pdf"}]},
        {area:"Dirección de Medio Ambiente",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_MEDIO_AMBIENTE.pdf"}]},
        {area:"Dirección de Obras Publicas",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_OBRAS_PUBLICAS.pdf"}]},
        {area:"Dirección de Odeapast",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_ODEAPAST.pdf"}]},
        {area:"Dirección de Participacion Ciudadana",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_PARTICIPACION_CIUDADANA.pdf"}]},
        {area:"Dirección de Salud",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_SALUD.pdf"}]},
        {area:"Dirección de Servicios Publicos",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_SERVICIOS_PUBLICOS.pdf"}]},
        {area:"Dirección de transporte Transporte Y Vialidad",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_TRANSPORTE_Y_VIALIDAD.pdf"}]},
        {area:"Dirección de Turismo",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DE_TURISMO.pdf"}]},
        {area:"Dirección del instituto municipal de cultura fisica y deporte Instituto Municipal De Cultura Fisica Y Deporte Teotihuacan Imcufidet",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DEL_INSTITUTO_MUNICIPAL_DE_CULTURA_FISICA_Y_DEPORTE_TEOTIHUACAN_IMCUFIDET.pdf"}]},
        {area:"Dirección del instituto municipal de Instituto Municipal De La Juventud",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/DIRECCION_DEL_INSTITUTO_MUNICIPAL_DE_LA_JUVENTUD.pdf"}]},
        {area:"Jefe de la oficina La Oficina De Presidencia",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/JEFE_DE_LA_OFICINA_DE_PRESIDENCIA.pdf"}]},
        {area:"Oficialia mediadora Y Conciliadora",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/OFICIALIA_MEDIADORA_Y_CONCILIADORA.pdf"}]},
        {area:"Registro civil",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/REGISTRO_CIVIL.pdf"}]},
        {area:"Secretaria del Ayuntamiento",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/SECRETARIA_DEL_AYUNTAMIENTO.pdf"}]},
        {area:"Secretaria del consejo municipal de Consejo Municipal De Seguridad Publica",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/SECRETARIA_DEL_CONSEJO_MUNICIPAL_DE_SEGURIDAD_PUBLICA.pdf"}]},
        {area:"Secretario tecnico Uippe",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/SECRETARIO_TECNICO_UIPPE.pdf"}]},       
        {area:"Tesorería Municipal",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/TESORERIA MUNICIPAL.pdf"}]},
        {area:"UIPEE",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/UIPEE.pdf"}]},
        {area:"Unidad de Transparencia",archivos:[{nombre:"Acta Constitutiva", link:"https://teotihuacan.gob.mx/files/mregulatoria/actas/comites/2023/UNIDAD_DE_TRANSPARENCIA.pdf"}]},

    ],
    };
  },
  mounted: function(){
      var host = location.protocol + '//' + location.host;
      axios({
          method: 'get',
          url: host+'/files/inicio.json',
      })
      .then(res => {
          this.resources = res.data;
      }).catch(err => {
        alert(err);  
      });
    },
  computed: {

    // pages: function () {
        
    //     let pages = [null,]//null primera pagina para que solo aparezca la portada

    //     for (let i = 1; i <= this.resources.numero_paginas_bando; i++) {
    //           let image_num = i.toString().padStart(4,0)        
    //           pages.push('/files/bando/'+this.resources.prename_paginas_bando+image_num+'.jpg')
    //     }
    //     return pages;
    //   }
   }
}
</script>

<style>
.flipbook .viewport {
    width: 90vw;
    height: 90vh;
}
.flipbook .bounding-box {
  box-shadow: 0 0 20px #000;
}

</style>